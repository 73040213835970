@charset "UTF-8";

/**
 *  Reset/Normalize
 * ------------------------------------------------------------------------- */
@import url('./normalize.css');
@import url('./reset.css');

/* Google fonts */
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap');

input,
select,
textarea {
    border-radius: 0; /* Removes border radius applied by Safari */
}

/**
 *  Variables
 * ------------------------------------------------------------------------- */
:root {
    --colour__primary:              #8DB147;
    --colour__primary--dark:        #8DB147;
    --colour__secondary:            #DE7AAD;
    --colour__secondary--dark:      #BD5196;
    --colour__font:                 #636362;
    --colour__font-alt:             #FFF;
    --colour__grey:                 #E1E1E1;
    --colour__grey--dark:           #9D9C9C;
    --colour__grey--light:          #E6E6E6;
    --colour__white:                #FFF;
    --font:                         Titillium Web,verdana,sans-serif;
    --transistion:                  400ms ease-in-out all;
}

.container {
    max-width: 940px;
    width: calc(100% - 30px);
    margin: 0 auto;
    padding: 0;
}

/*
 *  Typography
 *------------------------------------------------------------------------- */
html {
    font-size: 62.5%;
}

body {
    font-weight: 400;
    font-family: var(--font);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

.typography {
    color: var(--colour__font);
    font-size: 1.4rem;
    line-height: 1.7;
}

.typography p {
    font-size: 1.8rem;
}

.typography strong {
    font-weight: 500;
}


.typography em {
    font-style: italic;
}

.typography hr {
    display: block;
    width: 100%; height: 0;
    margin: 36px 0 24px;
    border: 0;
    border-bottom: solid 1px var(--colour__font);
    outline: 0;
}

.typography h1,
.typography .h1 {
    max-width: 664px;
    margin: 0 auto;
    margin-bottom: 1em;
    color: var(--colour__secondary);
    font-weight: 500;
    font-size: 2.4rem;
    text-align: center;
}


@media only screen and (min-width: 600px) {
    .typography h1,
    .typography .h1 {
        font-size: 3.7rem;
    }
}

.typography h2,
.typography .h2 {
    margin-bottom: 1em;
    color: var(--colour__font);
    font-weight: 500;
    font-size: 1.5rem;
}

@media only screen and (min-width: 600px) {
    .typography h2,
    .typography .h2 {
        font-size: 1.8rem;
    }
}


.typography h3,
.typography .h3 {
    margin-bottom: 1em;
    color: var(--colour__secondary);
    font-weight: 500;
    font-size: 1.5rem;
}

@media only screen and (min-width: 600px) {
    .typography h3,
    .typography .h3 {
        font-size: 1.6rem;
    }
}

.typography ul {
    margin: 0 0 1em;
    padding: 0 0 0 1.5em;
    list-style-type: none;
}

.typography ul li:before {
    position: relative;
    left: -0.5em;
    content: "+";
    color: #b2cf7c;
    line-height: 1.5;
}

.typography ol {
    margin: 0 0 1em; padding: 0 0 0 1.5em;
    list-style-type: decimal;
}


.typography blockquote {
    margin: 0 0 1em;
    font-style: italic;
}

.typography blockquote p {
    margin: 0;
}

.typography cite {
    display: block;
    font-weight: bold;
    font-style: normal;
}

.typography sup,
.typography sub {
    position: relative;
    top: -0.4em;
    font-size: 80%;
    vertical-align: baseline;
}


.typography sub {
    top: 0.4em;
}


.typography u {
    text-decoration: none;
}


.typography a {
    color: inherit;
}

.typography a:link,
.typography a:visited {
     text-decoration: underline;
 }

.typography a:hover,
.typography a:active {
     text-decoration: none;
}

.typography .center {
    text-align: center;
}

/**
 *  Main
 * ------------------------------------------------------------------------- */
.main {
    margin: 0 auto;
    padding: 20px 0 20px 0;
    text-align: center;
}

/*
 *  Spinner for 'option' component
 *------------------------------------------------------------------------- */
#circular_spinner {
    height: 64px;
    width: 64px;
    color: #f5cde1;
    position: relative;
    display: inline-block;
    border: 5px solid;
    border-radius: 50%;
    border-right-color: var(--colour__secondary);
    animation: rotate 1s linear infinite;;
}

@keyframes rotate {
    0% {
      transform: rotate(0); }
    100% {
      transform: rotate(360deg); } 
}

.loader{
    margin: 3em 0 5em 0;
}

/**
*  Container
* ------------------------------------------------------------------------- */
.container {
   max-width: 1235px;
   width: 100%;
   margin: 0 auto;
   padding: 0 10px;
}


/*
 *  Header
 *------------------------------------------------------------------------- */
.header .container{
    padding: 20px 0 20px 0;
}

.topHeader{
    /* background-color: var(--colour__grey--light); */
    /*background-color: var(--colour__white); */
    /* margin-bottom: 1em; */
    color: var(--colour__font);
    font-weight: 500;
    font-size: 1.8rem;
}

.main__top {
    padding: 4px 0 10px;
    text-align: center;
}

@media only screen and (min-width: 850px) {
    .main__top {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
}

.main__top-logo {
    padding: 20px;
    margin: 10px 29px;
    display: inline-block;
}

.main__top-logo img{
    width: 237px;
    height: 69px;
}

/* Header for Payment form*/
.header__supplementary {
    width: 100%;
    border-bottom: 1px solid #d5579b;
}

/* Footer */

.footer {
    padding: 85px 0 0;
    background: url(../../../images/bg-footer.svg) no-repeat 50% 30px;
    background-size: 786px 63px;
    font-size: 0.8rem;
    line-height: 1.2;
}

.footer a {
    color: #6d6e70;
}

.footer a:link, .footer a:visited {
    text-decoration: underline;
}

.footer a:active, .footer a:hover {
    text-decoration: none;
}

/* .footer p {
    margin: 0 0 1em;
} */

.footer small {
    font-size: 83.33333%;
}

.footer__content {
    padding: 20px 0;
    color: #fff;
    background: #d5579b;
}

.footer__content a {
    color: #fff;
}

.footer__left {
    margin: 0 0 20px;
    text-align: left;
}

.footer__logo {
    width: 216px;
    height: 67px;
    margin-top: -1.2em;
    background-image: url(../../../images/logos/iprs-group.svg);
    background-size: 100% 100%;
}

.footer__content {
    padding: 20px 0;
    color: #fff;
    background: #d5579b;
}

    .footer__content a {
        color: #fff;
    }

.footer__left {
    margin: 0 0 20px;
}

.footer__logo {
    width: 216px;
    height: 67px;
    margin-top: -1.2em;
    background-image: url(../../../images/logos/iprs-group.svg);
    background-size: 100% 100%;
}

@media screen and (min-width:768px) {
    .footer__left {
        float: left;
        margin: 0;
    }

    .footer__right {
        float: right;
    }

    .footer__right p {
        float: right;
        clear: right;
    }
}

.group {
    zoom: 1;
}

.group:after, .group:before {
    display: table;
    content: "";
}

.group:after {
    clear: both;
}


.replace {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 150%;
}


@media screen and (min-width:480px) {
    .header__logo {
        margin: 0;
    }

    .typography img.left {
        float: left;
        margin: 0 1em 1em 0;
    }

    .typography img.right {
        float: right;
        margin: 0 0 1em 1em;
    }

    .typography img.leftAlone {
        float: left;
        margin-right: 100%;
    }
}

@media screen and (min-width:650px) {
    .footer__content {
        padding: 28px 0;
        text-align: left;
    }

    .header {
        font-size: 1.4rem;
    }
}

@media screen and (min-width:768px) {
    .footer__left {
        float: left;
        margin: 0;
    }

    .footer__right {
        float: right;
    }

        .footer__right p {
            float: right;
            clear: right;
        }

    .header .container {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        padding-right: 0;
        padding-left: 0;
    }

    .header__logo {
        margin: 0;
    }

    .header__supplementary {
        line-height: 1.8;
        text-align: right;
    }
}

@media screen and (min-width:850px) {
    .form__fieldset--gutter {
        margin-bottom: 40px;
    }

    .main__top-logo {
        margin: 0;
    }

    .header__logo {
        margin: 0;
    }
}

@media screen and (min-width:940px) {
    .container {
        width: 940px;
        margin-right: auto;
        margin-left: auto;
    }

    .footer {
        background-position: 50% 26px;
    }

        .footer p {
            margin: 0;
        }

        .footer .social {
            margin: 1em 0;
        }

    .footer__content {
        padding: 35px 0;
    }
}

@media screen and (min-width:960px) {
    .header .container {
        padding-bottom: 40px;
    }
}

.green-button:hover {
    cursor: pointer;
    opacity: .65;
}
