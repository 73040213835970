/* Referral Stylesheet */

/**
 *  Form
 * ------------------------------------------------------------------------- */
.container {
    font-size: 1.5em;
}

body{
    color: #6d6e70
}

.formStatus{
    font-size: 1.3rem;
    margin-bottom: 1em;
}

#warning{
    font-size: 1.1em;
    color: var(--colour__font);
    text-align: left;
    margin-bottom: 1em;
 }

.form {
    /* padding: 36px 33px 42px; */
    background-color: var(--colour__form);
    /* max-width: 664px; */
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: left;
    border-radius: 20px;
}

input {
    writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    cursor: text;
    margin: 0em;
    border-width: 1px;
    border-style: inset;
    border-image: initial;
    height: 23px;
    border-radius: 2px;
}

textarea{
    border-radius: 2px;
    width: 340px;
}

select{
    border-radius: 2px;
}

input[disabled] {
    cursor: default;
}

input:disabled {
    cursor: default;
    background-color: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84), rgb(170, 170, 170);
    border-color: rgba(118, 118, 118, 0.3);
}

@media only screen and (min-width: 675px) {
    .form {
        text-align: left;
    }
}

.form h2 {
    color: var(--colour__secondary);
    font-size: 1.8rem;
}

.form hr {
    opacity: .5;
}

.form__bottom {
    margin-bottom: 40px;
}

.form__field {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 8px;
}

.form__field input, .form__field select, .form__field .date_parts {
    width: 340px;
}

.form__field .date_parts input {
    width: 50px;
}

.form__fieldset--gutter {
    margin-bottom: 20px;
}

.form__legend {
    display: block;
    margin-bottom: .5em;
    color: #d5579b;
    font-weight: 700;
}

.form__field input {
    width: 340px;
}

.form__field label{
    white-space: pre-wrap;
}

.date_parts, .card_parts {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
}

.form__action{
    padding: 0px 8px 2px 8px;
    cursor: pointer;
    height: 25px;
}

@media only screen and (min-width: 675px) {
    .form__field {
        display: flex;
        align-items: center;
    }
}

.form__date-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 675px) {
    .form__date-wrapper {
        justify-content: flex-start;
    }
}

label{
    white-space: nowrap; 
    margin-right: 20px;
}

.form__label {
    font-weight: 500;
    flex-shrink: 0;
    display: block;
    margin-right: 16px;
    margin-bottom: 7px;
    margin-left: 16px;
}

@media only screen and (min-width: 675px) {
    .form__label {
        width: 146px;
        margin-bottom: unset;
        margin-left: 0;
    }
}

.form__label.form__label--auto-width {
    width: auto;
}

.form__label.form__label--margin-x {
    margin-right: 12px;
    margin-left: 12px;
}


.form__input,
.form__dropdown,
.form__textarea {
    height: 30px;
    color: var(--colour__font);
    background: var(--colour__grey);
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    width: 100%;
    padding-left: 11px;
    padding-right: 11px;
}

.form__dropdown {
    background: transparent;
    position: relative;
    z-index: 2;
    text-align: left;
}

.form__dropdown::-ms-expand {
    display: none;
}

.form__dropdown-wrapper {
    background: var(--colour__grey);
    width: 100%;
    position: relative;
}

@media only screen and (min-width: 675px) {
    .form__dropdown-wrapper.form__dropdown-wrapper--width-100 {
        width: 100px;
    }

    .form__dropdown-wrapper.form__dropdown-wrapper--width-280 {
        width: 280px;
    }
}

.form__dropdown-wrapper:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 9px solid var(--colour__secondary);
    position: absolute;
    top: 50%; right: 11px;
    transform: translateY(-50%);
}

.form__input.form__input--2-chars {
    width: 40px;
    text-align: center;
}

.form__input.form__input--4-chars {
    width: 66px;
    text-align: center;
}

@media only screen and (min-width: 675px) {
    .form__input.form__input--2-chars {
        margin-right: 22px;
    }
}

@media only screen and (min-width: 675px) {
    .form__input.form__input--set-width {
        max-width: 209px;
        width: 100%;
    }
}

@media only screen and (min-width: 675px) {
    .form__input.form__input--small-width {
        max-width: 130px;
        width: 100%;
    }
}

.form__input--mobile-y-bottom {
    margin-bottom: 16px;
}

@media only screen and (min-width: 675px) {
    .form__input--mobile-y-bottom {
        margin-bottom: 0;
    }
}

.form__textarea {
    height: 100px;
    padding: 11px;
    resize: none;
    overflow-y: scroll;
}

.form p.form__description {
    display: block;
    font-size: 1.3rem;
    margin-top: 28px;
    margin-left: 18px;
}

@media only screen and (min-width: 675px) {
    .form p.form__description {
        margin-left: 160px !important;
    }
}

.form .button--postcode {
    width: 100%;
    margin-top: 16px;
    border: none;
}

@media only screen and (min-width: 675px) {
    .form .button--postcode {
        margin-left: 16px;
        margin-top: 0;
        min-width: 207px;
    }
}

@media screen and (min-width:850px) {
    .form__fieldset--gutter {
        margin-bottom: 40px;
    }
}

.referralFormCheckBox {
    width: 340px;
}

.referralFormCheckBox input {
    width: 20px;
    float: left;
}

#referralFormCheckBoxLabel{
    min-width: 160px;
    max-width: 100%;
}

/* @media screen and (min-width:630px) {
    .referralFormCheckBoxLabel{
        width: auto;
    }
} */