/* button{
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
} */

.infoWindow{
    color: #fff;
    font-size: 1em;
    text-align: left;
}

.infoWindow h3{
    font-weight: 600;
    font-size: 1em;
    margin-bottom: 0.8em;
}

.infoWindow Button{
    border: none;
    padding: 14px;
    margin: 10px 0px;
    cursor: pointer;
}

.gm-style-iw-d{
    overflow: hidden !important;
    max-width: 166px;
    min-width: 166px;
}

.gm-style-iw.gm-style-iw-c{
    background: linear-gradient(#DE7AAD, var(--colour__secondary--dark));
    max-width: 648px;
    padding: 17px !important;
    padding-top: 19px !important;
    margin-top: 40px;
    /* padding-right: 8px !important; 
    padding-bottom: 8px !important; */
}

.gm-ui-hover-effect>span {
    background-color: #fff;
}

.gm-style .gm-style-iw-tc::after {
    background: #BD5196;
}

.green-button.info_window{
    font-size: 1.05em;
    margin-top: 18px;
    margin-bottom: 8px;
}

.green-button.selectClinicButton{
    margin: 10px auto;
}

.clinicAddressButtons{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center; 
}

@media (min-width: 500px){
    .green-button.selectClinicButton{
        margin: 10px;
    }

    .clinicAddressButtons{
        display: flex;
        flex-direction: row;
    }
}


.gm-style-iw-tc{
    margin-top: 40px;
}

.gm-ui-hover-effect{
    margin: 6px !important;
}

.green-button.info_window {
    font-size: 1.05em;
    padding: 10px 30px;
}

.panelTop{
    background-color: #D75094;
    padding-bottom: 1.2em;
}

.travelMode{
    display:flex;
    flex-direction: row;
}

p.public-transport-warning {
    line-height: 1.5;
    font-size: 1.3rem;
    padding: 1em 1.5em 0em 1.5em;
    text-align: left;
    color: #000;
    font-weight: 700;
    visibility: visible;
    height: 50px;
    opacity: 1;
    transition: height 300ms, opacity 600ms ease;
}

.public-transport-warning.public-transport-warning-hidden {
    opacity: 0;
    height: 0;
    visibility: hidden;
}

.adp-warnbox{
    display: none;
}

.homeAndDestination div{
    color: white;
    text-align: left;
    font-size: 14px;
    margin: 0.7em;
    line-height: 1.6em;
}

.warnbox-content{
    padding: 0.5em 1.5em;
}

.custom-warnbox{
    background: #fff1a8;
    padding: 0.5em 1.5em;
    margin: 0.8em 0em 0.8em 0em;
    font-size: 1.3rem;
    line-height: 1.5em;
    transition: opacity 300ms ease;
}

.adp-summary{
    font-weight: 600;
    font-size: 0.9em;
    margin-bottom: 1em;
}

.adp-agencies{
    display: none;
}

.adp-marker2{
    display: none;
}

.adp-text{
    display: none;
}

.adp-directions {
    text-align: left;
    letter-spacing: 0.5px;
    line-height: 1.2em;
    width: 100%;
    /* display: flex;
    justify-content: center; */
}

td.adp-substep {
    padding: 0.6em;
    font-size: 0.9em;
}

.travelMode{
    padding: 0.5em;
    width: 100%;
}

@media (min-width: 800px){
    .travelMode{
        padding: 0.5em;
        width: 100%;
        justify-content: space-between;
    }
}

.travel-mode-container {
    padding: 5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

input[type=radio] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    opacity: 0;
}

input[type=radio]:checked + .travel-mode-container {
    background-color: var(--colour__secondary--dark);
}

.leftPanel{
    width: 100%;
}

.map-container {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin-bottom: 65px;
    /*  overflow: auto; */
}

@media (min-width: 800px) {
    .leftPanel {
        height: 600px;
        min-width: 360px;
        max-width: 360px;
        overflow: scroll;
        overflow-x: hidden
    }

    .map-container{
        flex-direction: row;
        overflow: hidden;
        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    }
}

.AvivaPortalReferralSearchDiv{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.AvivaPortalReferralSearchDiv button{
    font-size: 17px;
    width: 206px;
    padding: 4px 0px 4px 0px;
    border: none;
    cursor: pointer;
}

.AvivaPortalReferralSearchLabelAndInputDiv label{
    text-align: right;
    clear: both;
    float: left;
    font-size: 17px;
    padding-top: 4px;
}

.AvivaPortalReferralSearchLabelAndInputDiv input{
    width: 250px;
    padding: 17px 10px 20px 10px;
    font-size: 1.8rem;
    margin-right: 10px;
}

@media (max-width: 650px) {
    .AvivaPortalReferralSearchDiv{
        display:flex;
        flex-direction: column;
        margin: 20px;
        align-items: center;
    }

    .AvivaPortalReferralSearchDiv label{
        text-align: center;
        margin: 0px;
    }

    .AvivaPortalReferralSearchDiv input{
        margin: 10px 0px 0px 0px;
        padding-top: 20px;
        text-align:center;
    }

    .AvivaPortalReferralSearchDiv button{
        margin-top: 20px;
        padding: 10px 0px 10px 0px;
        border-radius: 10px;
    }

    .AvivaPortalReferralSearchLabelAndInputDiv{
        display: flex;
        flex-direction: column;
    }
}

.referralSearchText {
    font-size: 15px;
    font-weight: bold;
    margin:20px;
    width:70%;
    margin-left:auto;
    margin-right: auto;
}

.green-button > span {
    margin-top: 4px;
}

.altServicetext{
    margin-top: 50px;
    line-height: 20px;
}