.IprsPortalFileTreeHeading {
    font-weight: bold;
    margin-bottom: 3em;
    margin-top: 1em;
}

.IprsPortalMIReportExplorerDiv{
    margin: 20px 20px 60px 20px;
    color: #636362;
    font-size: 16px;
    border-radius: 12px;
    padding: 6px;
}

.IprsPortalFileTreeHeading{
    font-weight: bold; 
    margin-bottom: 1em; 
    margin-top: 1em;
    font-size: 16px;
}

.iprsPortalFileTreeDirectoryFileDiv{
    display: flex;
    align-items:center;
}

.iprsPortalFileTreeDirectoryFileDiv:hover{
    background-color:#8DB147;
}

.iprsPortalFileTreeDirectoryItem{
    margin: 2px 2px 2px 10px;
    padding: 2px;
    border-radius: 6px;
    text-decoration: underline;
}

.iprsPortalFileTreeDirectoryIconAndNameDiv{
    cursor: pointer;
    display:flex;
}

.iprsPortalFileTreeDirectioryIconsAndName{
    display:flex;
    margin-bottom: 4px;
}

.iprsPortalFileTreeDirectoryIconDiv{
    display: flex;
    align-items:center;
    justify-content: space-between;
    width: 100%;
}

.iprsPortalFileTreeDirectoryIconDiv:hover{
    text-decoration: underline;
}

.iprsPortalFileTreeNameForDirectoryAndFile{
    margin-left:6px;
    display: flex;
    align-items: center;
}

.iprsPortalFileTreeDictionaryItem{
    display: flex;
    flex-direction: row;
}

.iprsPortalFileTreeDictionaryItem:hover{
    cursor: pointer;
}

.iprsPortalFileTreeDictionaryItemButton{
    display: flex;
    align-items: center;
    margin: 0;
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
    background-color: #fff;
    color: #636362;
    font-size: 15px;
    font-weight: 400;
}

.iprsPortalFileTreeDictionaryItemButton:hover{
    text-decoration: underline;
    opacity: 100%;
}

.iprsPortalFileTreeDictionaryItemDownloadText{
    margin-left: 5px;
    font-size: 14px;
}

.iprsPortalFileTreeDirectoryItem .deleted{
    display: none;
}

.iprsPortalFileTreeDirectoryItem :hover{
    background-color: #fff;
}

.iprsPortalFileTreeAddNewDirectoryRow{
    display: flex;
}
.iprsPortalFileTreeAddNewDirectoryRow input{
    padding: 0px;
    border-radius: 8px;
    border: none;

    padding: 10px;
    border: 1px solid #636362;
}

.iprsPortalFileTreeItemAddDirectoryButton{
    margin: 0px;
    height: 44px;
    margin:0;
    margin-left: 5px;
    border-radius: 8px;
    background-color:#DE7AAD;
    text-decoration: none;
}

.iprsPortalFileTreeItemAddDirectoryButton:disabled{
    background-color:#DE7AAD;

}

.iprsPortalFileTreeItemAddDirectoryButton:hover{
    text-decoration: none;
    background-color:#8DB147;
}

.iprsPortalFileTreeAddNewDirectoryRowInput{
    padding: 10px;
    border: 1px solid #636362;
}

.iprsPortalFileTreeAddDirectoryRowErrorMessage{
    display: flex;
    align-items: center;
    margin-left: 14px;
}

.iprsPortalFileTreeFileNameTextAndDownloadLink{
    height: 18px;
}

.iprsPortalFileTreeFileNameTextAndDownloadLink:hover{
    text-decoration: underline;
}
.iprsPortalFileTreeDictionaryItemButtonDownloadIcon{
    margin-left: 10px;
}
