.rating-star {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.rating-stars{
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    margin-top: 12px;
    margin-bottom: 55px;
}